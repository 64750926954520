import MDinput from '@/components/MDinput';
import PickRec from '@/components/DSE/PickRec';
import { validatorRequire } from '@/utils/validators';

export function getFormDefinition(vue) {
  return {
    invoiceMap: {
      type: MDinput,
      defaultValue: '',
      props: {
        caption: 'hermes.invoiceMap',
        maxlength: 64,
        required: true
      },
      rules: [{ validator: validatorRequire }]
    },
    hermesProductID: {
      type: PickRec,
      defaultValue: null,
      props: {
        caption: 'hermes.product',
        entity: 'HermesProduct',
        valueField: 'id',
        labelField: 'code',
        inputField: 'description',
        columns: [{ field: 'code', label: 'hermes.productCode' }, 'description'],
        maxlength: 16,
        required: true,
        usePagination: false,
        hideInput: false,
        suggest: true,
        readonly: true
      },
      rules: [{ validator: validatorRequire }]
    },
    hermesZoneID: {
      type: PickRec,
      defaultValue: null,
      props: {
        caption: 'hermes.zone',
        entity: 'HermesZone',
        valueField: 'id',
        labelField: 'zone',
        inputField: 'zone',
        columns: [{ field: 'zone', label: 'hermes.zone' }, 'description'],
        maxlength: 16,
        required: true,
        usePagination: false,
        hideInput: false,
        suggest: true,
        readonly: true
      },
      rules: [{ validator: validatorRequire }]
    },
    combi: {
      type: MDinput,
      defaultValue: '',
      props: {
        caption: 'hermes.combi',
        maxlength: 32,
        required: false
      },
      rules: []
    },
    postFix: {
      type: MDinput,
      defaultValue: '',
      props: {
        caption: 'hermes.postFix',
        maxlength: 16,
        required: false
      },
      rules: []
    }
  };
}

export default getFormDefinition;

export function createItemFromFormData(vue, form) {
  return form;
}

export function createFormDataFromItem(vue, item) {
  const form = {
    ...item
  };
  return form;
}
